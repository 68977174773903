<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList"></Breadcrumb>
    <a-form
      ref="form"
      class="al-form"
      layout="inline"
      :model="form"
      :label-col="{ style: 'width: 80px' }"
      :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item>
        <a-input v-model="form.content" placeholder="评价内容" />
      </a-form-item>
      <a-form-item>
        <a-select v-model="form.status" placeholder="状态">
          <a-select-option :value=0>待审核</a-select-option>
          <a-select-option :value=1>已审核</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table
      :data-source="list"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="pagination">
      <span slot="action1" slot-scope="scope">
        <div>{{scope.usUserName}}</div>
        <div>{{scope.usUserId}}</div>
      </span>
      <span slot="action2" slot-scope="scope">
        <div>内容编号：{{scope.idContent}}</div>
        <div>评价信息：{{scope.content}}</div>
      </span>
      <span slot="action3" slot-scope="scope">
       <span>{{scope===0?'待审核':'已审核'}}</span>
      </span>
      <span slot="action4" slot-scope="scope">
        <span>{{scope.reply===0?'否':'是'}}</span>
        <span v-if="scope.replyQty > 0">- {{scope.replyQty}}回复</span>
      </span>
      <span slot="action5" slot-scope="scope">
        <span v-if="scope.applaudQty > 0">赞 {{scope.applaudQty}} </span>
        <span v-if="scope.opposeQty > 0">踩 {{scope.opposeQty}}</span>
      </span>
      <span slot="action" slot-scope="scope">
         <a href="#" v-if="scope.status === 0" @click="approved(scope)"><a-spin size="small" v-if="scope.isLoading" />审核</a>
         <a-divider v-if="scope.status === 0" type="vertical" />
        <a-popconfirm
            title="你确定要删除这行内容吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm(scope)"
            @cancel="cancel"
          >
            <a href="#"><a-spin size="small" v-if="scope.isLoading" />删除</a>
          </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      currentTenantId: this.$store.state.user.currentTenantId,
      breadList: [
        {
          name: '评价管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      form: {
        content: '',
        status: undefined
      },
      columns: [
        { title: '用户', key: 'usUserName', width: 200, scopedSlots: { customRender: 'action1' } },
        { title: '评价信息', key: 'content', width: 300, scopedSlots: { customRender: 'action2' } },
        { title: '状态', dataIndex: 'status', width: 80, key: 'status', scopedSlots: { customRender: 'action3' } },
        { title: '回复信息', key: 'reply', width: 120, scopedSlots: { customRender: 'action4' } },
        { title: '互动信息', key: 'qtyInfo', width: 150, scopedSlots: { customRender: 'action5' } },
        { title: '更新时间', dataIndex: 'gmtModified', key: 'gmtModified', width: 180 },
        { title: '操作', scopedSlots: { customRender: 'action' }, key: 'operation', fixed: 'right', width: 120 }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    // 重置表单
    resetForm () {
      this.form = {
        content: '',
        status: undefined
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        evaluateType: 0
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'evaluatePage',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      records.forEach(item => {
        item.isLoading = false
      })
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    },
    cancel () {},
    // 删除评论
    confirm (scope) {
      this.del(scope)
    },
    async del (scope) {
      scope.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'evaluateDelete',
        query: {
          id: scope.id
        }
      })
      if (res) {
        scope.isLoading = false
        this.$store.dispatch('showToast', { message: '删除成功~' })
        this.queryList()
      }
    },
    // 审核
    approved (scope) {
      this.$store.dispatch('showConfirm', {
        message: '确认审核通过吗？',
        yes: async _ => {
          scope.isLoading = true
          await this.$store.dispatch('http', {
            api: 'evaluateApproved',
            query: {
              id: scope.id
            },
            complete: () => {
              scope.isLoading = false
              this.$store.dispatch('showToast', { message: '审核成功~' })
              this.reloadList()
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }
  .form__items {
    padding-top: 6px;
  }
}
</style>
